<template>
<div class="activity-category">
  <MyBreadcrumb></MyBreadcrumb>
  <div class="activity-category-cont">
    <el-button type="primary" @click="add">添加专题</el-button>
    <el-table
        :data="tableData"
        class="mt20"
        border
    >
      <el-table-column
          prop="id"
          label="ID"
          width="80"
      >
      </el-table-column>
      <el-table-column
          prop="name"
          label="专题名称"
      >
      </el-table-column>
      <el-table-column
          prop="desc"
          label="专题描述"
      >
      </el-table-column>
      <el-table-column
          prop="name"
          label="添加时间"
          width="200"
      >
        <template slot-scope="scope">
          {{util.timeFormatter(new Date(+scope.row.created_at*1000), 'yyyy-MM-dd hh:mm')}}
        </template>
      </el-table-column>
      <el-table-column
          prop="address"
          label="操作"
          width="180"
      >
        <template slot-scope="scope">
          <el-button type="text" size="small" @click="edit(scope.row)">编辑</el-button>
          <el-button type="text" size="small" @click="dele(scope.row)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
<!--    <div class="page">-->
<!--      <MyPage :total="total" :current="page" @changePage="changePage" @changePageSize="changePageSize"></MyPage>-->
<!--    </div>-->

    <el-drawer
        title="添加/编辑专题"
        :visible.sync="drawer">
      <div class="drawer-title" slot="title">
        <div class="drawer-title-text">添加/编辑专题</div>
        <div class="drawer-title-btns">
          <Button class="cancel" style="margin-right:10px;" @click="cancel">取消</Button>
          <Button type="primary" @click="ok" :loading="addBtnLoading" >确定</Button>
        </div>
      </div>
      <div class="add-cont">
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="130px" class="demo-ruleForm">
          <el-form-item label="设置专题名称" prop="name">
            <el-input type="text" v-model="ruleForm.name" maxlength="10"></el-input>
          </el-form-item>
          <el-form-item label="设置专题描述" prop="desc">
            <el-input type="textarea" :rows="2" maxlength="30" v-model="ruleForm.desc"></el-input>
          </el-form-item>
        </el-form>
      </div>

    </el-drawer>
  </div>
  <DeleModal :status="deleModalStatus" :txt="deleText" :loading="modalLoading" @cancel="cancelDele" @confirm="confirmDele"></DeleModal>
</div>
</template>

<script>
import util from '@/utils/tools.js';
import DeleModal from '../../components/deleteModal.vue';
export default {
  name: "category.vue",
  data(){
    return {
      util:util,
      tableData:[],
      // total:0,
      // page:1,
      // pageSize:10
      drawer:false,
      addBtnLoading:false,
      ruleForm:{
        name:'',
        desc:'',
      },
      rules: {
        name: [
          { required: true, message: '请输入活动名称', trigger: 'blur' },
        ],
        desc: [
          { required: true, message: '请输入活动名称', trigger: 'blur' },
        ],
      },
      curData:{},
      deleModalStatus:false,
      deleText:'确定删除吗？',
      modalLoading:false,
    }
  },
  components:{
    DeleModal
  },
  created(){
    this.getList()
  },
  methods:{
    getList(){
      // let params = {
      //   page:this.page,
      //   pageSize:this.pageSize
      // }
      this.api.activity.activityCategoryList().then((res)=>{
        this.tableData = res.list;
        // this.total = Number(res.count);
      })
    },
    add(){
      this.drawer = true;
      this.curData = {};
      this.ruleForm.desc = '';
      this.ruleForm.name = '';
    },
    cancel(){
      this.drawer = false;
    },
    ok(){
      this.$refs['ruleForm'].validate((valid) => {
        if (valid) {
          let params = {
            name:this.ruleForm.name,
            desc:this.ruleForm.desc,
          }
          this.addBtnLoading = true;
          if(this.curData.id){
            params.id =  this.curData.id;
            this.api.activity.activityCategoryUpdate(params).then((res)=>{
              this.drawer = false;
              this.addBtnLoading = false;
              this.getList();
            }).catch((err)=>{
              this.addBtnLoading = false;
            })
          }else{
            this.api.activity.activityCategoryCreate(params).then((res)=>{
              this.drawer = false;
              this.addBtnLoading = false;
              this.getList();
            }).catch((err)=>{
              this.addBtnLoading = false;
            })
          }

        }
      });
    },
    edit(data){
      this.curData = data;
      this.ruleForm.name = this.curData.name;
      this.ruleForm.desc = this.curData.desc;
      this.drawer = true;
    },
    dele(data){
      this.curData = data;
      this.deleModalStatus = true;
    },
    cancelDele(){
      this.deleModalStatus = false;
    },
    confirmDele(){
      let data = {
        id:this.curData.id,
      }
      this.modalLoading = true;
      this.api.activity.activityCategoryDelete(data).then((res)=>{
        this.modalLoading = false;
        this.deleModalStatus = false;
        this.getList();
      }).catch((err)=>{
        this.modalLoading = false;
      });

    },
  }
}
</script>

<style scoped lang="scss">
.activity-category{
  margin: 20px;
  .activity-category-cont{
    padding: 20px;
    background: #FFFFFF;

    .add-cont{
      padding: 30px 40px;
    }

  }
}
</style>
